import { Dialog } from '@headlessui/react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import upperFirst from 'lodash.upperfirst'
import { DialogWrapper } from '../../../syndication-compatibility/DialogWrapper'
import {
    AutoSuggest,
    CloseIcon,
    usePrevious,
} from '@artnetworldwide/ui-library'
import { buildClassName, lit } from '@artnetworldwide/ui-library/utils'
import { encodeUriSearchParam, getRootDomain } from '~/utils'
import { formatDistanceToNow } from 'date-fns'
import clientConfig from '~/config/config.client'
import type { NewsSearchHint, SearchHint } from '~/services/graphql/generated'
import { SearchType } from '~/services/graphql/generated'
import { Hint, SearchTypeProperties } from '../types'
import type { ServerTemplateParamGetterFunc } from '~/syndicated-components/hooks'

const MIN_INPUT_CHARACTERS = 3

type SearchTypePropertyMap = {
    [key in SearchType]: SearchTypeProperties
}

function handleHintSelection(hint: Hint) {
    window.location.href = hint.url!
}

function handleHeaderHintSelection(
    hint: Hint | Pick<Hint, 'searchType' | 'searchString'>
) {
    let url: string
    const urlScheme = 'https://'
    const rootDomain =
        process.env.NODE_ENV === 'development' &&
        clientConfig.URL_SCHEME_AND_AUTHORITY.includes('localhost')
            ? 'artnet-dev.com'
            : getRootDomain(new URL(clientConfig.URL_SCHEME_AND_AUTHORITY))
    switch (hint.searchType) {
        case SearchType.Global:
        case SearchType.Auctions:
            const searchCategory =
                hint.searchType === SearchType.Global
                    ? 'search/?'
                    : 'auctions/fts/?'
            const urlParams =
                hint.searchType === SearchType.Global
                    ? new URLSearchParams({ q: hint.searchString! })
                    : new URLSearchParams({ f: hint.searchString! })

            // the legacy search page doesn't like + symbols - this fixes FE-1142
            const legacySearchQueryString = urlParams
                .toString()
                .replace(/\+/g, '%20')

            url = `${urlScheme}${rootDomain}/${searchCategory}${legacySearchQueryString}`
            break
        case SearchType.News:
            url = `${urlScheme}news.${rootDomain}/search/${encodeUriSearchParam(
                hint.searchString!
            )}`
            break
    }
    window.location.href = url
}

function formatNewsArticleDate(date: NewsSearchHint['date']) {
    const formattedDate = formatDistanceToNow(new Date(date), {
        addSuffix: true,
    })
    return upperFirst(formattedDate)
}

function processSearchHints(
    results: SearchHint[],
    searchType: SearchType,
    searchString: string
) {
    return results.map((result) => ({
        title: result.name,
        searchType,
        searchString,
        subTitle: result.description,
        author: (result as any).author
            ? (result as NewsSearchHint).author
            : null,
        date: (result as any).date
            ? formatNewsArticleDate((result as NewsSearchHint).date)
            : null,
        url: result.url,
        isHeaderHint: false,
    })) as Hint[]
}

const searchTypePropertiesMap: SearchTypePropertyMap = {
    [SearchType.Global]: {
        label: 'Global Search',
        placeholder:
            'Search for Artworks, Artists, News, Events, Galleries, Auction Houses.',
        headerSearches: [
            {
                title: 'Search for {{searchString}}',
                searchType: SearchType.Global,
                isHeaderHint: true,
            },
            {
                title: 'Search for {{searchString}} within Artnet Auctions',
                searchType: SearchType.Auctions,
                isHeaderHint: true,
            },
            {
                title: 'Search for {{searchString}} within Artnet News',
                searchType: SearchType.News,
                isHeaderHint: true,
            },
        ],
    },
    [SearchType.Auctions]: {
        label: 'Artnet Auctions',
        placeholder: 'Search for Artists on Artnet Auctions.',
        headerSearches: [
            {
                title: 'Search for {{searchString}} within Artnet Auctions',
                searchType: SearchType.Auctions,
                isHeaderHint: true,
            },
        ],
        advancedSearchHref: '/auctions/search/',
    },
    [SearchType.News]: {
        label: 'Artnet News',
        placeholder: 'Search for Subjects, Titles and Authors on Artnet News.',
        headerSearches: [
            {
                title: 'Search for {{searchString}} within Artnet News',
                searchType: SearchType.News,
                isHeaderHint: true,
            },
        ],
    },
}

interface Tab {
    label: string
    searchType: SearchType
}

const tabs: Array<Tab> = Object.entries(searchTypePropertiesMap).map(
    ([key, value]) => {
        return {
            label: value.label,
            searchType: key as unknown as SearchType,
        } as Tab
    }
)

function useGlobalHeaderSearchView({
    searchResult,
    isSearchOpen,
    onToggleSearchOpen,
    siteBaseUrl,
}: {
    searchResult: SearchHint[]
    isSearchOpen?: boolean
    onToggleSearchOpen?: () => void
    siteBaseUrl: string
}) {
    const [selectedTab, setSelectedTab] = useState<Tab>(tabs[0])
    const [hints, setHints] = useState<Array<Hint>>([])
    const [searchString, setSearchString] = useState<string>('')
    const inputRef = useRef<HTMLInputElement>(null)

    const selectedType = useMemo(() => selectedTab.searchType, [selectedTab])

    const selectedSearchType = useMemo(
        () => searchTypePropertiesMap[selectedType],
        [selectedType]
    )

    const headerHints: Array<Hint> = useMemo(
        () =>
            selectedSearchType.headerSearches.map((hint: Hint) => ({
                ...hint,
                searchString,
            })),
        [selectedSearchType.headerSearches, searchString]
    )

    const onSelectTab = useCallback((tab: Tab) => {
        setSelectedTab(tab)
        setHints([])
        inputRef.current?.focus()
    }, [])

    const onSelectHint = useCallback(
        (hint: Hint) => {
            hint.isHeaderHint
                ? handleHeaderHintSelection(hint)
                : handleHintSelection(hint)
            onToggleSearchOpen?.()
        },
        [onToggleSearchOpen]
    )

    useEffect(() => {
        if (searchResult?.length) {
            const processedHints = processSearchHints(
                searchResult,
                selectedType,
                searchString
            )
            setHints(processedHints)
        }
    }, [selectedType, selectedSearchType, searchResult, searchString])

    const inputAside = useMemo(() => {
        if (selectedSearchType.advancedSearchHref) {
            return (
                <a
                    className={`absolute right-4 top-4 hidden text-xs text-black lg:block dark:text-neutral-400 `}
                    href={siteBaseUrl + selectedSearchType.advancedSearchHref}
                >
                    Advanced Search
                </a>
            )
        }
        return null
    }, [selectedSearchType, siteBaseUrl])

    return {
        headerHints,
        hints,
        setSearchString,
        searchString,
        inputAside,
        inputRef,
        isSearchOpen,
        placeholder: selectedSearchType.placeholder,
        selectedTab,
        tabs,
        onSelectHint,
        onSelectTab,
        onToggleSearchOpen,
        setHints,
        selectedType,
    }
}

function HeaderHintTitle({
    title,
    searchString = '',
}: {
    title: string
    searchString: string
}) {
    const titleParts = title.split('{{searchString}}')
    const searchStringPrefix = titleParts[0]
    const searchStringSuffix = titleParts[1]

    const formattedSearchString = `"${searchString}"`

    return (
        <>
            {searchStringPrefix}
            <span className="pl-0.5 not-italic">{formattedSearchString}</span>
            {searchStringSuffix}
        </>
    )
}

export function SearchTabs({
    tabs,
    selectedTab,
    onTabSelected,
    className,
}: {
    tabs: Tab[]
    selectedTab: Tab
    onTabSelected: (tab: Tab) => void
    className?: string
}) {
    return (
        <div className={`flex items-start gap-4 text-sm ${className}`}>
            {tabs.map((tab) => {
                const isSelected = tab === selectedTab
                return (
                    <button
                        key={tab.searchType}
                        type="button"
                        className={`pb-0.5 text-xs ${
                            isSelected
                                ? 'border-brand-black border-b-2 font-bold'
                                : 'border-transparent text-neutral-700 dark:text-neutral-400'
                        }`}
                        data-testid={`search-${tab.searchType.toLocaleLowerCase()}`}
                        onClick={() => onTabSelected(tab)}
                    >
                        {tab.label}
                    </button>
                )
            })}
        </div>
    )
}

export function SearchBar({
    inputAside,
    inputRef,
    headerHints,
    hints,
    placeholder,
    onFetchHints,
    onSelectHint,
    onEnterKeyPressed,
    getServerTemplateParam,
}: {
    headerHints: Array<Hint>
    hints: Array<Hint>
    inputAside: React.ReactNode
    inputRef: React.RefObject<HTMLInputElement>
    placeholder: string
    onFetchHints: (query: string) => void
    onSelectHint: (hint: Hint) => void
    onEnterKeyPressed: (inputValue?: string) => void
    getServerTemplateParam: ServerTemplateParamGetterFunc
}) {
    const maxWidthInRemsVal = getServerTemplateParam('maxWidthInRems')

    return (
        <AutoSuggest
            autoFocus
            fetchHints={onFetchHints}
            headerHints={headerHints}
            hintPaddingClassName="px-4 py-2 lg:px-8"
            hints={hints}
            hintToString={(hint) => hint?.title ?? ''}
            inputAside={inputAside}
            inputRef={inputRef}
            inputBorderClassName="border-none"
            minInputLength={MIN_INPUT_CHARACTERS}
            listboxClassName="!mt-0 lg:pt-6 lg:pb-6 border-t border-neutral-200 w-screen -ml-4 lg:-ml-8"
            onSelectHint={onSelectHint}
            onEnterKeyPressed={onEnterKeyPressed}
            placeholder={placeholder}
            searchIconPosition="left"
            openMenuOnFocus={true}
            inputClassName="focus:ring-0 dark:bg-black dark:text-white dark:border-white"
            ulClassName="dark:bg-black dark:text-white"
            ulStyle={{ maxWidth: `${maxWidthInRemsVal}rem` }}
        >
            {(hint) => {
                const title = hint.isHeaderHint ? (
                    <>
                        <HeaderHintTitle
                            title={hint.title}
                            searchString={hint.searchString ?? ''}
                        />
                    </>
                ) : (
                    hint.title
                )

                return (
                    <div className="flex items-center gap-4">
                        <div className="flex flex-col truncate">
                            <div className="break-words tracking-wide text-black dark:text-white">
                                {title}
                            </div>

                            {hint.author && hint.date ? (
                                <div className="text-xs text-neutral-500">
                                    {hint.date} - {hint.author}
                                </div>
                            ) : (
                                <div className="text-xs tracking-wide text-neutral-500">
                                    {hint.subTitle}
                                </div>
                            )}
                        </div>
                    </div>
                )
            }}
        </AutoSuggest>
    )
}

export function GlobalHeaderSearchView({
    siteBaseUrl,
    searchResult,
    fetchHints,
    isSearchOpen,
    onToggleSearchOpen,
    desktopHeight,
    theme,
    getServerTemplateParam,
}: {
    siteBaseUrl: string
    searchResult: SearchHint[]
    fetchHints: (searchTerm: string, searchType: SearchType) => void
    isSearchOpen: boolean
    onToggleSearchOpen: () => void
    desktopHeight: number
    theme: string
    getServerTemplateParam: ServerTemplateParamGetterFunc
}) {
    const {
        headerHints,
        hints,
        setHints,
        setSearchString,
        searchString,
        inputAside,
        inputRef,
        placeholder,
        selectedTab,
        tabs,
        onSelectTab,
        onSelectHint,
        selectedType,
    } = useGlobalHeaderSearchView({
        searchResult,
        isSearchOpen,
        onToggleSearchOpen,
        siteBaseUrl,
    })

    const prevSelectedTab = usePrevious(selectedTab.searchType)
    const prevSearchString = usePrevious(searchString)

    const maxWidthInRemsVal = getServerTemplateParam('maxWidthInRems')

    useEffect(() => {
        if (
            selectedType !== prevSelectedTab ||
            searchString !== prevSearchString
        ) {
            setHints([])
            fetchHints(searchString, selectedType)
        }
    }, [
        selectedType,
        prevSelectedTab,
        prevSearchString,
        hints,
        searchString,
        setHints,
        fetchHints,
    ])

    function handleSearch(input: string) {
        setSearchString(input)
        fetchHints(input, selectedType)
    }

    function handleEnterKeyPressed(input?: string) {
        if (input && input.length >= MIN_INPUT_CHARACTERS) {
            handleHeaderHintSelection({
                searchType: selectedType,
                searchString: input,
            })
        }
    }

    const heightClasses = `lg:h-[${desktopHeight}px]`

    return (
        <Dialog
            open={isSearchOpen}
            onClose={onToggleSearchOpen}
            // note: z-index of 1500 needed to ensure high enough z-index on legacy auctions site
            className={lit`${buildClassName(
                'GlobalHeaderSearchView'
            )} relative z-[1500] ${theme}`}
            as={DialogWrapper}
        >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-x-0 top-0 flex items-end justify-start bg-white dark:bg-black dark:text-white">
                <Dialog.Panel
                    style={{ maxWidth: `${maxWidthInRemsVal}rem` }}
                    className={lit`relative mx-auto flex w-full flex-col justify-end px-4 lg:px-8 lg:pt-4 ${heightClasses}`}
                >
                    <div className="absolute right-4 top-2 lg:right-8 lg:top-4">
                        <button type="button" onClick={onToggleSearchOpen}>
                            <CloseIcon size="medium"></CloseIcon>
                        </button>
                    </div>
                    <SearchTabs
                        tabs={tabs}
                        selectedTab={selectedTab}
                        onTabSelected={onSelectTab}
                        className="mt-3 lg:mt-0"
                    />
                    <hr className="mt-4 border-b border-neutral-200" />
                    <SearchBar
                        hints={hints}
                        onFetchHints={handleSearch}
                        headerHints={headerHints}
                        inputAside={inputAside}
                        placeholder={placeholder}
                        onSelectHint={onSelectHint}
                        onEnterKeyPressed={handleEnterKeyPressed}
                        inputRef={inputRef}
                        getServerTemplateParam={getServerTemplateParam}
                    />
                </Dialog.Panel>
            </div>
        </Dialog>
    )
}
